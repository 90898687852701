<template>
  <section id="hotel">
    <div class="row my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          Revisa las solicitudes por colaborador y actualiza su estado. Recuerda
          que podrás seleccionar un periodo de interés y descargar un reporte.
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-6 col-lg-6">
            <select v-model="filter.service" class="form-select form-control input-custom">
              <option value="">Todos los servicios</option>
              <option v-for="(serv,i) in serviciosHoteleria" :key="i" :value="serv.id" >
                {{ serv.label }}
              </option>
            </select>

          </div>
          <div class="col-6 col-lg-6">
            <select v-model="filter.status" class="form-select form-control input-custom">
              <option value="">Todos los estados</option>
              <option v-for="(estado,i) in estadosHoteleria" :key="i" :value="estado.id" >
                {{ estado.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div
            class="col-12 col-lg-4 col-xl-4 col-xxl-4 d-flex align-items-center justify-content-lg-end text-secondary"
          >
            Filtrar por periodo:
          </div>
          <div class="col-10 col-lg-7 col-xl-7 col-xxl-7">
            <div class="row">
              <div class="col-6">
                <InputDate
                  :max="range.date_end"
                  placeHolder="Inicio"
                  @dateEmit="captureDateStart"
                />
              </div>
              <div class="col-6">
                <InputDate
                  :min="range.date_start"
                  placeHolder="Término"
                  @dateEmit="captureDateEnd"
                />
              </div>
            </div>
          </div>
          <div class="col-2 col-lg-1 d-flex">
            <export-json-excel
              class="btn btn-custom btn-custom-color-blue border-round-50 flex-fill"
              :data="data_excel"
              :fields="fields_excel"
              :name="name_excel"
              :beforeExport="getDataExcel"
              :afterExport="() => (this.loading = false)"
            >
              <font-awesome-icon icon="download" />
            </export-json-excel>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-7">
        <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
          <div class="card-body">
            <div class="row px-2 py-2">
              <div
                class="col-6 font-main-bold font-size-18 color-gray d-flex align-items-center justify-content-start"
              >
                Realiza una búsqueda
              </div>
              <div class="col-6 col-lg-6 input-search">
                <input
                  type="search"
                  class="form-control input-custom"
                  placeholder="Buscar colaborador"
                  v-model.trim="filter.search"
                  ref="searchInput"
                />
                <span class="input-search-icon">
                  <font-awesome-icon icon="search" />
                </span>
              </div>
            </div>
            <Loading v-if="loading" pixeles="150px" />
            <div
              v-if="!loading && filteredSolicitudes.length !== 0"
              class="row font-main-bold font-size-16 color-gray px-2"
            >
              <div
                class="col-4 col-lg-4 d-flex justify-content-start align-items-center"
              >
                <label class="form-check-label input-check-custom mx-0">
                  <input
                    @click="onAllCheck($event.target.checked)"
                    v-model="all_check"
                    :checked="checks.length == filteredSolicitudes.length"
                    class="form-check-input link-cursor"
                    type="checkbox"
                  />
                </label>
                Nombre usuario
              </div>
              <div
                class="col-3 col-lg-3 d-flex justify-content-center align-items-center"
              >
                <p class="m-0">Servicio</p>
              </div>
              <div
                class="col-2 col-lg-2 d-flex justify-content-center align-items-center"
              >
                <p class="m-0">Urgencia</p>
              </div>
              <div
                class="col-3 col-lg-3 d-flex justify-content-center align-items-center"
              >
                <p class="m-0">Estado</p>
              </div>
            </div>
            <ul
              v-if="!loading && filteredSolicitudes.length !== 0"
              class="list-group list-group__scroll list-group-flush scroll-custom-550 mt-3"
            >
              <li
                v-for="(ad, i) in filteredSolicitudes"
                :key="ad.id_solicitud"
                href="javascript:"
                class="list-group-item"
                :class="selectedUserList(ad.id_solicitud)"
              >
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <label class="form-check-label input-check-custom">
                      <input
                        :ref="`typeBox`"
                        v-model="checks"
                        :checked="selectedUserList(ad.id_solicitud)"
                        :value="{
                          idUsuario: ad.usuario.id_usuario,
                          idEstado: ad.estado.id_estado,
                          idEmpresa: ad.id_empresa_fk,
                          idSolicitud: ad.id_solicitud,
                        }"
                        class="form-check-input link-cursor"
                        type="checkbox"
                      />
                    </label>
                  </div>
                  <a
                    href="javascript:"
                    @click="selectSoli(ad, i)"
                    class="flex-grow-1 ms-3 text-decoration-none"
                  >
                    <div class="row">
                      <div class="col-4 col-lg-4">
                        <div
                          class="d-flex justify-content-start align-items-center"
                        >
                          <div class="flex-shrink-0">
                            <img
                              class="img-obj img-obj-60 img-obj-circle border"
                              :src="handleUserImage(ad.usuario.imagen_perfil)"
                            />
                          </div>
                          <div class="flex-grow-1 ms-3">
                            <p class="text-secondary mb-0">
                              {{ ad.usuario.primer_nombre }}
                              {{ ad.usuario.apellido_paterno }}
                              {{ ad.usuario.apellido_materno }}
                              <br />
                              {{ ad.descripcion_solicitud }}
                              <br />
                              {{ dateNewFormat(ad.fecha_creacion, 1) }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-3 col-lg-3 text-center d-flex align-items-center justify-content-center"
                      >
                        <p class="font-size-16 text-secondary mb-0">
                          {{
                            ad.detalles[0].detalle.id_servicio_fk
                              .nombre_servicio
                          }}
                        </p>
                      </div>
                      <div
                        class="col-2 col-lg-2 text-center d-flex align-items-center justify-content-center"
                      >
                        <div class="row">
                          <div class="col-12 status-ball__container">
                            <div
                              :class="
                                'status-ball__ball ' +
                                (ad.urgencia.nombre_urgencia === 'Alta'
                                  ? 'bg-danger'
                                  : ad.urgencia.nombre_urgencia === 'Media'
                                  ? 'bg-warning'
                                  : 'bg-success')
                              "
                            ></div>
                            <p class="text-secondary status-ball__text">
                              {{ ad.urgencia.nombre_urgencia }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-3 col-lg-3 text-end d-flex align-items-center justify-content-center"
                      >
                        <div
                          class="status-text__container text-center"
                          :style="{
                            color: ad.estado.color || 'defaultColor',
                            backgroundColor: `${ad.estado.color}20` || 'defaultBackgroundColor'
                          }"
                        >
                          <p class="status-text__text">
                            {{ ad.estado.nombre_estado }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ul>
            <SinResultados
               msg="Aceptar" 
               custom-click 
               @click="nuevaBusqueda"
              v-if="!loading && filteredSolicitudes.length < 1"
            />
            <div class="row mt-3" v-if="!loading">
              <div class="col-12">
                <button
                  class="btn btn-custom-color-white border d-block"
                  :disabled="paginaData.next_page === '0'"
                  @click="paginador()"
                >
                  <i class="fa-solid fa-arrows-rotate color-blue pe-1"></i
                  >Mostrar más (
                  {{ pagina_actual + " / " + paginaData.total_pages }})
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-5">
        <div class="card border-round-10 card-shadow border-0 mb-3">
          <div class="card-body">
            <div class="row mb-3">
              <div
                class="col-12 d-flex align-items-center justify-content-between"
              >
                <h5 class="font-main-bold color-gray mb-0">
                  Información del usuario
                </h5>
              </div>
            </div>

            <div v-if="checks.length >= 2" class="alert alert-info text-center">
              ¿Cambiar el estado de las
              <strong>{{ checks.length }} solicitudes?</strong>
            </div>
            <div v-if="checks.length >= 2">
              <div class="col-md-12">
                <label for="form_state_list" class="form-label">Estado</label>
                <select
                  aria-placeholder="Seleccionar estado"
                  id="form_state_list"
                  class="form-select"
                  v-model="form_state"
                >
                  <option
                    v-for="(state, index) in [
                      newStatePlaceHolder,
                      ...estadosHoteleria,
                    ]"
                    :value="state.id"
                    :key="index"
                    :selected="newStatePlaceHolder.id"
                    :disabled="state.id == newStatePlaceHolder.id"
                  >
                    {{ state.label }}
                  </option>
                </select>
              </div>
            </div>
            <form class="row g-2" v-else>
              <div class="col-md-6">
                <label for="form_name" class="form-label">Nombre</label>
                <input
                  type="text"
                  class="form-control to-copy"
                  id="form_name"
                  placeholder="Nombre de solicitante"
                  readonly
                  v-model="form_name"
                  @click="copiarAlPortapapeles($event.target.value)"
                />
              </div>
              <div class="col-md-6">
                <label for="form_state_list" class="form-label">Estado</label>
                <select
                  aria-placeholder="Seleccionar estado"
                  id="form_state_list"
                  class="form-select"
                  v-model="form_state"
                >
                  <option
                    v-for="(state, index) in [
                      newStatePlaceHolder,
                      ...estadosHoteleria,
                    ]"
                    :value="state.id"
                    :key="index"
                    :selected="newStatePlaceHolder.id"
                    :disabled="state.id == newStatePlaceHolder.id"
                  >
                    {{ state.label }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="form_cellphone" class="form-label">Teléfono</label>
                <input
                  type="text"
                  class="form-control to-copy"
                  @click="copiarAlPortapapeles($event.target.value)"
                  id="form_cellphone"
                  readonly
                  placeholder="Teléfono de contacto"
                  v-model="form_cellphone"
                />
              </div>
              <div class="col-md-6">
                <label for="form_email" class="form-label">Correo</label>
                <input
                  type="text"
                  class="form-control to-copy"
                  @click="copiarAlPortapapeles($event.target.value)"
                  id="form_email"
                  readonly
                  placeholder="Correo de contacto"
                  v-model="form_email"
                />
              </div>
              <div class="col-md-6">
                <label for="form_turn" class="form-label">Turno</label>
                <input
                  type="text"
                  class="form-control to-copy"
                  @click="copiarAlPortapapeles($event.target.value)"
                  id="form_turn"
                  readonly
                  placeholder="Turno de solicitante"
                  v-model="form_turn"
                />
              </div>
              <div class="col-md-6">
                <label for="form_team" class="form-label">Team</label>
                <input
                  type="text"
                  class="form-control to-copy"
                  @click="copiarAlPortapapeles($event.target.value)"
                  id="form_team"
                  readonly
                  placeholder="Team de solicitante"
                  v-model="form_team"
                />
              </div>
              <div class="col-md-6">
                <label for="form_hall" class="form-label">Pabellón</label>
                <input
                  type="text"
                  class="form-control to-copy"
                  @click="copiarAlPortapapeles($event.target.value)"
                  id="form_hall"
                  readonly
                  placeholder="Pabellón de solicitante"
                  v-model="form_hall"
                />
              </div>
              <div class="col-md-6">
                <label for="form_room" class="form-label">Habitación</label>
                <input
                  type="text"
                  class="form-control to-copy"
                  @click="copiarAlPortapapeles($event.target.value)"
                  readonly
                  placeholder="Habitación de solicitud"
                  id="form_room"
                  v-model="form_room"
                />
              </div>
              <div class="col-md-6">
                <label for="form_bed_location" class="form-label"
                  >Ubicación cama</label
                >
                <input
                  type="text"
                  class="form-control to-copy"
                  @click="copiarAlPortapapeles($event.target.value)"
                  id="form_bed_location"
                  readonly
                  placeholder="Ubicación de cama"
                  v-model="form_bed_location"
                />
              </div>
              <div class="col-md-6">
                <label for="form_closet_location" class="form-label"
                  >Ubicación closet</label
                >
                <input
                  type="text"
                  class="form-control to-copy"
                  @click="copiarAlPortapapeles($event.target.value)"
                  readonly
                  placeholder="Ubicación de closet"
                  id="form_closet_location"
                  v-model="form_closet_location"
                />
              </div>
            </form>
            <div
              class="row hoteleria-msg mt-4"
              v-if="form_msg.length > 0 && form_msg_view"
            >
              <article
                class="col-12 hoteleria-msg__content"
                v-for="msg in form_msg"
                :key="msg.id_mensaje"
              >
                <header
                  class="row hoteleria-msg__user"
                  v-if="msg.administrador"
                >
                  <small class="text-secondary col-8">
                    <strong
                      >{{ msg.administrador.primer_nombre }}
                      {{ msg.administrador.apellido_paterno }}</strong
                    ></small
                  >
                  <small class="col-4 hoteleria-msg__text-r">
                    {{ dateNewFormat(msg.fecha_creacion, 1) }}</small
                  >
                  <small class="text-secondary col-8">{{
                    msg.administrador.correo
                  }}</small>
                  <small class="col-4 hoteleria-msg__text-r"
                    >Vistos {{ msg.visto }}</small
                  >
                </header>
                <main>
                  <p class="hoteleria-msg__msg">{{ msg.mensaje }}</p>
                </main>
              </article>
              <div class="hoteleria-msg__content-footer"></div>
            </div>
            <div
              class="row"
              :class="form_msg.length > 0 && form_msg_view ? 'mt-2' : 'mt-4'"
            >
              <div class="col-6">
                <button
                  class="btn btn-custom btn-custom-color-blue border-round-50 letter-spacing-1"
                  @click="toggleMsgView"
                >
                  Mensajería <small>&nbsp;({{ form_msg.length }})</small>
                </button>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <button
                  class="btn btn-custom btn-custom-color-blue border-round-50 letter-spacing-1"
                  @click="saveNewState"
                >
                  Guardar cambios
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import "export-json-excel";
import moment from "moment";

//FF
import InputDate from "@/components/forms/InputDate.vue";
import Loading from "@/components/Loading.vue";
import SinResultados from "@/components/SinResultados.vue";

export default {
  components: {
    Loading,
    InputDate,
    SinResultados,
  },
  data() {
    return {
      form_name: "",
      form_state: -1,
      form_cellphone: "",
      form_email: "",
      form_turn: "",
      form_team: "",
      form_hall: "",
      form_room: "",
      form_bed_location: "",
      form_closet_location: "",
      form_msg: [],
      form_msg_view: false,

      newStatePlaceHolder: {
        id: -1,
        label: "Seleccionar estado",
        type: "state",
      },
      data_excel: [],
      fields_excel: [],
      name_excel: "",
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      pagina_actual: 1,
      user_default_img: require("@/assets/img/user_default_img.svg"),
      all_check: false,
      checks: [],
      id_param: this.$route.params.id,

      //FF
      loading: true,
      filter: {
        search: "",
        service: "",
        status: "",
      },
      range: {
        date_start: null,
        date_end: null,
        mask_start: null,
        mask_end: null,
      },
      sel_selected_estados: { id: 0, label: "Todas", type: "Todas" },
      filteredSolicitudes: []

    };
  },
  created() {
    let data = { idEmpresa: this.id_empresa };
    this.getEstadosSolicitud(data);
    this.getServiciosHoteleria(data);
    this.getPaginasByInit();
  },
  computed: {
    ...mapState("hotelsModule", [
      "solicitudesListPaginado",
      "serviciosHoteleria",
      "estadosHoteleria",
      "paginaData",
    ])
  },
  watch: {

    "filter.service"() {
      this.filterSolicitudes();
    },
    "filter.status"() {
      this.filterSolicitudes();
    },
    $route: {
      handler: function () {
        this.pagina_actual = 1;
        this.id_param = this.$route.params.id;
        this.all_check = true;
        this.all_check = false;
      },
      immediate: true,
    },
    "filter.search"() {
      this.filterSolicitudes();
    },
    "range.date_start"() {
      this.getPaginas();
    },
    "range.date_end"() {
      this.getPaginas();
    },
  },
  methods: {
    ...mapActions("hotelsModule", [
      "getSolicitudesAdminPaginado",
      "getSolicitudesAdminExcel",
      "getEstadosSolicitud",
      "getServiciosHoteleria",
      "changeState",
    ]),
    nuevaBusqueda(){
      this.filter.search = '';
      this.$refs.searchInput.focus();
    },
    filterSolicitudes() {
      this.form_msg_view = false;
      this.pagina_actual = 1;

      let filtered = this.solicitudesListPaginado;

      if (this.filter.search) {
        const searchLower = this.filter.search.toLowerCase();
        filtered = filtered.filter(doc => (doc.usuario.primer_nombre+doc.usuario.apellido_paterno).toLowerCase().includes(searchLower));
      }
      if (this.filter.service) {
        filtered = filtered.filter(sol => +sol.detalles[0].detalle.id_servicio_fk.id_servicio === this.filter.service);
      }
      
      if (this.filter.status) {
        filtered = filtered.filter(sol => +sol.estado.id_estado === this.filter.status);
      }

      this.filteredSolicitudes = filtered;
    },

    copiarAlPortapapeles(txt) {
      if (txt)
        navigator.clipboard
          .writeText(txt)
          .then(() => {
            this.$toast.open({
              message: `Texto copiado correctamente.`,
              type: "success",
              duration: 5000,
              position: "top-right",
            });
          })
          .catch(() => {
            this.$toast.open({
              message: `Error al copiar el texto.`,
              type: "warning",
              duration: 5000,
              position: "top-right",
            });
          });
    },
    toggleMsgView() {
      this.form_msg_view = !this.form_msg_view;
    },
    selectedUserList(id) {
      var results = this.checks.filter((check) => {
        return check.idSolicitud == id;
      });
      return results.length > 0 && "bg-black-light";
    },
    selectSoli(sol, i) {
      this.form_name = `${sol.usuario.primer_nombre} ${sol.usuario.apellido_paterno}`;
      this.form_state = sol.estado.id_estado;
      this.form_cellphone = sol.usuario.telefono_movil;
      this.form_email = sol.usuario.correo;
      this.form_turn =
        sol.usuario.turno.length > 0 ? sol.usuario.turno[0].nombre_turno : "";
      this.form_team = sol.usuario.team && sol.usuario.team.nombre_team;
      this.form_hall = sol.habitacion.id_pabellon_fk.nombre_pabellon || "";
      this.form_room = sol.habitacion.nombre_habitacion;
      this.form_bed_location = sol.cama.nombre_ubicacion;
      this.form_closet_location = sol.closet.nombre_ubicacion;
      this.form_msg = sol.mensajes;
      this.form_msg_view = false;

      this.$nextTick(() => {
        this.$refs.typeBox[i].focus();
      });
      this.checks = [];
      this.checks.push({
        idUsuario: sol.usuario.id_usuario,
        idEstado: sol.estado.id_estado,
        idEmpresa: sol.id_empresa_fk,
        idSolicitud: sol.id_solicitud,
      });
    },
    paginador() {
      this.pagina_actual += 1;
      this.getPaginas();
    },
    getPaginasByInit() {
      this.form_msg_view = false;
      this.pagina_actual = 1;
      this.getPaginas();
    },

    async getPaginas() {
      this.loading = true;
      let data_ = {
        nombreUsuario: this.filter.search,
        pagina: this.pagina_actual,
        idEstado: +this.filter.status,
        idServicio: +this.filter.service,
        fechaIni: this.range.date_start !== null ? this.range.mask_start : "",
        fechaFin:
          this.range.date_end !== null
            ? this.range.mask_end
            : moment().endOf("month").format("YYYY-MM-DD"),
      };
      try {
        await this.getSolicitudesAdminPaginado(data_);
        this.filteredSolicitudes = this.solicitudesListPaginado;
      } catch (err) {
        console.log("Falla getPaginacionSolicitudes: ", err);
      }
      this.loading = false;
    },
    
    async getDataExcel() {
      this.loading = true;
      let data_ = {
        nombreUsuario: this.filter.search,
        idEstado: +this.filter.status,
        idServicio: +this.filter.service,
        fechaIni: this.range.date_start !== null ? this.range.mask_start : "",
        fechaFin:
          this.range.date_end !== null
            ? this.range.mask_end
            : moment().endOf("month").format("YYYY-MM-DD"),
      };
      try {
        const data = await this.getSolicitudesAdminExcel(data_);
        await this.formatExcelData(data);
      } catch (err) {
        console.log("Falla getPaginacionSolicitudes: ", err);
      }
      this.loading = false;
    },
    formatExcelData(data) {
      (this.data_excel = []),
        (this.fields_excel = [
          { title: "ID", name: "id" },
          { title: "Nombre", name: "name" },
          { title: "Rut", name: "rut" },
          { title: "Correo", name: "mail" },
          { title: "Telefono", name: "phone" },
          { title: "Servicio", name: "service" },
          { title: "Detalles", name: "details" },
          { title: "Urgencia", name: "urgency" },
          { title: "Estado", name: "state" },
          { title: "Fecha", name: "date" },
          { title: "Habitación", name: "room" },
          { title: "Ubicación cama", name: "bed_location" },
          { title: "Ubicación closet", name: "closet_location" },
        ]),
        (this.name_excel =
          "Reporte_de_solicitudes_en_hoteleria_" +
          moment().format("YYYY-MM-DD") +
          "_" +
          moment().format("HH-mm"));

      return new Promise((res) => {
        for (let i = 0; i < data.length; i++) {
          const solicitud = data[i];
          this.data_excel.push({
            id: solicitud.id_solicitud,
            name: `${solicitud.usuario.primer_nombre} ${solicitud.usuario.apellido_paterno} ${solicitud.usuario.apellido_materno}`,
            rut: `${solicitud.usuario.rut_usuario}-${solicitud.usuario.dv_usuario}`,
            mail: solicitud.usuario.correo,
            phone: solicitud.usuario.telefono_movil,
            service:
              solicitud.detalles[0].detalle.id_servicio_fk.nombre_servicio,
            details: solicitud.detalles.reduce(
              (acc, curr) =>
                (acc.length === 0 ? acc : acc + ";") +
                (curr.detalle.nombre_detalle
                  ? curr.detalle.nombre_detalle
                  : ""),
              ""
            ),
            urgency: solicitud.urgencia.nombre_urgencia,
            state: solicitud.estado.nombre_estado,
            date: solicitud.fecha_creacion,
            room: solicitud.habitacion.nombre_habitacion,
            bed_location: solicitud.cama.nombre_ubicacion,
            closet_location: solicitud.closet.nombre_ubicacion,
          });
        }
        res();
      });
    },
    async saveNewState() {
      if (this.form_state === -1) {
        this.$toast.open({
          message: `Debes seleccionar un nuevo estado.`,
          type: "warning",
          duration: 5000,
          position: "top-right",
        });
        return;
      }
      if (this.checks.length === 0) {
        this.$toast.open({
          message: `Debes al menos seleccionar un usuario.`,
          type: "warning",
          duration: 5000,
          position: "top-right",
        });
        return;
      }
      this.loading = true;

      let newChecks = this.checks.map((check) => ({
        ...check,
        idEstado: `${this.form_state}`,
      }));
      try {
        await this.changeState(newChecks);
        await this.getPaginas();
      } catch (error) {
        this.toastGenericError();
      }
      this.loading = false;
    },

    onAllCheck(check) {
      if (check) {
        this.checkAllInputs();
      } else {
        this.checks = [];
      }
    },
    checkAllInputs() {
      this.checks = [];
      for (let i in this.solicitudesListPaginado) {
        this.checks.push({
          idUsuario: this.solicitudesListPaginado[i].usuario.id_usuario,
          idEstado: this.solicitudesListPaginado[i].estado.id_estado,
          idEmpresa: this.solicitudesListPaginado[i].id_empresa_fk,
          idSolicitud: this.solicitudesListPaginado[i].id_solicitud,
        });
      }
    },

    handleUserImage(img) {
      if (img !== null && img !== "" && img !== undefined) {
        return img;
      } else {
        return this.user_default_img;
      }
    },


    //Formatear fecha
    dateNewFormat(date, type) {
      var day = new Date(date);
      var month = day.getMonth();

      if (type == 1) {
        return `${day.getDate()}/${
          month + 1 < 10 ? `0${month + 1}` : month + 1
        }/${day.getFullYear()}`;
      }
      if (type == 2) {
        return `${day.getDate()};${this.meses[month]}`;
      }
    },
    //FF
    captureDateStart(date) {
      this.range.date_start = date;
      this.range.mask_start = moment(date).format("YYYY-MM-DD");
    },
    captureDateEnd(date) {
      this.range.date_end = date;
      this.range.mask_end = moment(date).format("YYYY-MM-DD");
    },
  },
  //FF
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>
